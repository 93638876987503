import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const NotFound = () => {
  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h2 className="text-center pt-6">
              Sajnos a keresett oldal nem található!
            </h2>

            <h4 className="text-center">
              <Link to="/">Tovább a főoldalra</Link>
            </h4>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
